<template>
  <div class="otp-input">
    <input
      v-for="(digit, index) in digits"
      :key="index"
      :ref="'digit' + index"
      v-model="digits[index]"
      type="number"
      maxlength="1"
      class="digit-input"
      @input="onInput($event, index)"
      @focus="onFocus()"
      @keydown.backspace="onInput($event, index)"
      @keydown="preventInvalidKeys($event)"
    >
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCOUNTS_PAGE, LOGIN_PAGE } from '../js/router/pages';
import authService from '../js/services/authService';

export default {
  props: {
    id:       Number,
    username: String,
    session:  String,
    init:     Boolean,
  },
  data() {
    return {
      digits:         Array(6).fill(''),
      sessionTimeout: null,
    };
  },
  mounted() {
    this.$refs.digit0[0].focus();

    const minutes = 5;
    const timeout = 1_000 * 60 * minutes;

    this.sessionTimeout = setTimeout(() => {
      this.$router.push({ name: LOGIN_PAGE });
    }, timeout);
  },
  methods: {
    onInput(event, index) {
      // ignore on paste
      if (event.inputType === 'insertFromPaste') {
        this.digits = event.target?.value ? event.target.value.split('') : this.digits;

        this.$refs[`digit${this.digits.length - 1}`][0].focus();

        if (this.digits.every(digit => digit !== '')) {
          this.submitCode();
        }

        return;
      }

      // handle backspace
      if (event.key === 'Backspace' && this.digits[index] === '' && index > 0) {
        console.log(`backspace: ${index}`);

        const prevInput = this.$refs[`digit${index - 1}`][0];

        prevInput.innerText = '';
        this.digits[index - 1] = '';

        prevInput.focus();

        return;
      }

      if (this.digits[index].length === 1 && index < this.digits.length - 1) {
        this.$refs[`digit${index + 1}`][0].focus();
      }

      if (this.digits.every(digit => digit !== '')) {
        this.submitCode();
      }
    },
    onFocus() {
      // Get first empty digit
      const emptyIndex = this.digits.findIndex(digit => digit === '');

      this.$refs[`digit${emptyIndex}`][0].select();
    },
    preventInvalidKeys(event) {
      if ([ 'e', 'E', '+', '-' ].includes(event.key)) {
        event.preventDefault();
      }
    },
    async submitCode() {
      try {
        await Vue.axios.post('/api/auth/mfa/verify', {
          username: this.$props.username,
          session:  this.$props.session,
          code:     this.digits.join(''),
          init:     this.$props.init,
        });

        await authService.processLogin(this.$props.id);

        clearTimeout(this.sessionTimeout);
        this.$router.push({ name: ACCOUNTS_PAGE });
      } catch (error) {
        const errorMessage = error?.response?.data?.debug_exception?.Message
          ?? error?.response?.data?.title
          ?? 'An error occurred';

        this.digits = Array(6).fill('');
        this.$refs.digit0[0].focus();

        this.$emit('verify-error', errorMessage);
      }
    },
  },
};
</script>

<style scoped>
.otp-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.digit-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
